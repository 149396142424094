<template>
  <div>
    <div class="container">
      <the-app-bar />
    </div>
    <div class="col-md-12 btn--grey text-center  py-20">
      <div class="container text-white">
        <h3 class="text-2x font-semibold " style="color: #f15825;">
          Contact us
        </h3>
        <h3 class="text-3xl fontsemibold" style="color: #fff;">
          A global easy, safe and secure direct payment network
        </h3>


      </div>
    </div>
    <div class="container pb-5">

      <div class="row">
        <div class="col-md-12 text-center  py-20">
        
          <section class="container mt-5">
            <!--Contact heading-->
            <div class="row">
              <!--Grid column-->
              <div class="col-sm-12 mb-4 col-md-5">
                <!--Form with header-->
                <div class="card border-primary rounded-0">
                  <div class="card-body p-3">
                    <h3>Customer Support</h3>
                    <p>
                      For support inquiries, please contact
                      <br />info@oonpay.com
                    </p>
                    <br />
                    <br />
                    <h3>Support Hours</h3>
                    <p>
                      Monday - Friday: 8:00 am EST - 10:00 pm EST <br />
                      Saturday: 9:00 am EST - 6:00 pm EST <br />
                      Sunday: Closed
                    </p>

                    <br />
                    <br />
                    <!-- <h3>Address</h3>
                    <p>
                      Send us a postcard <br />

                      Pomelo Card <br />

                      720York ST, Ste 116,<br />

                      San Francisco, CA 94110
                    </p> -->
                  </div>
                </div>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-sm-12 col-md-7">
                <!--Google map-->
                <div class="mb-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127066.7223844937!2d-0.24976999403772324!3d5.591375400022536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9084b2b7a773%3A0xbed14ed8650e2dd3!2sAccra!5e0!3m2!1sen!2sgh!4v1662024483641!5m2!1sen!2sgh"
                    width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                    tabindex="0"></iframe>
                </div>
                <!--Buttons-->

              </div>
              <!--Grid column-->
            </div>

            <div class="row text-center mt-5">
              <div class="col-md-4">
                <a class="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block"><i
                    class="fa fa-map-marker"></i></a>
                <p>
                  7th Floor(REGUS) Atlantic Tower <br />
                  Plot 16, Airport City Accra Ghana <br />
                  Tel: +233 536157651 <br>
                  +233 501965656
                </p>
              </div>
              <div class="col-md-4">
                <a class="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block"><i class="fa fa-phone"></i></a>
                <p>
                  Abuja Office <br />
                  Plot 16 Maygray Td Uphill<br />
                  Area B Ext Nyanya <br>
                  +234 8161523993
                </p>
              </div>
              <div class="col-md-4">
                <a class="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block"><i
                    class="fa fa-envelope"></i></a>
                <p>oonpay@gmail.com</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import TheAppBar from "../../components/NavBar/TheAppBar.vue";
// import getStarted from "../../components/provider/getStarted.vue";
export default {
  components: { Footer, TheAppBar },
};
</script>

<style lang="scss" scoped>
.btn--grey {
  background: #0c4369;

}
</style>
